import { useHistory } from 'react-router-dom';
import {
  CLIENT,
  THERAPIST,
  ADMIN,
  GENERAL,
  SUPER_ADMIN,
} from '../../constants/nav-routes';
import { useAuth } from '../../context/auth';
import { useOrg } from '../../context/organisation';
import * as S from './style';

const handleClick = (cb) => {
  if (typeof cb === 'function') {
    return cb(false);
  }
  return;
};

const LogoutRoute = ({ setOpen }) => {
  const history = useHistory();
  const { org } = useOrg();
  const { logout: logoutApi } = useAuth();
  return (
    <S.Link
      onClick={async () => {
        await logoutApi();
        handleClick(setOpen);
        org.id
          ? history.push(
              GENERAL.ORGANISATION_LOGIN.replace(':organisationName', org.name)
            )
          : history.push(GENERAL.LOGIN);
      }}
      to="#"
    >
      <S.Head4 weight="bold" color="gray8">
        Log
      </S.Head4>{' '}
      <S.Head4>out</S.Head4>
    </S.Link>
  );
};

export const ClientRoutes = ({ setOpen }) => {
  return (
    <>
      <S.Link to={CLIENT['DASHBOARD']} onClick={() => handleClick(setOpen)}>
        <S.Head4>My</S.Head4>{' '}
        <S.Head4 weight="bold" color="gray8">
          dashboard
        </S.Head4>
      </S.Link>
      <S.Link to={CLIENT['PROGRAMMES']} onClick={() => handleClick(setOpen)}>
        <S.Head4>My</S.Head4>{' '}
        <S.Head4 weight="bold" color="gray8">
          home programmes
        </S.Head4>
      </S.Link>
      <S.Link to={CLIENT['MY_THERAPIST']} onClick={() => handleClick(setOpen)}>
        <S.Head4>My</S.Head4>{' '}
        <S.Head4 weight="bold" color="gray8">
          therapist
        </S.Head4>
      </S.Link>
      <S.Link to={CLIENT['ACCOUNT']} onClick={() => handleClick(setOpen)}>
        <S.Head4>My</S.Head4>{' '}
        <S.Head4 weight="bold" color="gray8">
          account
        </S.Head4>
      </S.Link>
      <LogoutRoute setOpen={setOpen} />
    </>
  );
};

export const TherapistRoutes = ({ setOpen }) => (
  <>
    <S.Link to={THERAPIST['DASHBOARD']} onClick={() => handleClick(setOpen)}>
      <S.Head4>My</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        dashboard
      </S.Head4>
    </S.Link>
    <S.Link to={THERAPIST['LIBRARY']} onClick={() => handleClick(setOpen)}>
      <S.Head4>My</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        library
      </S.Head4>
    </S.Link>
    <S.Link to={THERAPIST['FEEDBACK']} onClick={() => handleClick(setOpen)}>
      <S.Head4>My</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        feedback
      </S.Head4>
    </S.Link>
    <S.Link to={THERAPIST['ACCOUNT']} onClick={() => handleClick(setOpen)}>
      <S.Head4>My</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        account
      </S.Head4>
    </S.Link>
    <LogoutRoute setOpen={setOpen} />
  </>
);

export const AdminRoutes = ({ setOpen }) => (
  <>
    {/* TO BE ADDED ONCE FEEDBACK PAGES HAVE BEEN CREATED */}
    <S.Link to={ADMIN.DASHBOARD} onClick={() => handleClick(setOpen)}>
      <S.Head4>My</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        dashboard
      </S.Head4>
    </S.Link>

    <S.Link to={ADMIN.ALL_CONTENT} onClick={() => handleClick(setOpen)}>
      <S.Head4>All</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        content
      </S.Head4>
    </S.Link>
    <S.Link to={ADMIN.MY_THERAPISTS} onClick={() => handleClick(setOpen)}>
      <S.Head4>My</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        therapists
      </S.Head4>
    </S.Link>
    <S.Link to={ADMIN.ALL_FEEDBACK} onClick={() => handleClick(setOpen)}>
      <S.Head4>All</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        feedback
      </S.Head4>
    </S.Link>
    <S.Link to={ADMIN.ACCOUNT} onClick={() => handleClick(setOpen)}>
      <S.Head4>My</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        account
      </S.Head4>
    </S.Link>
    <LogoutRoute setOpen={setOpen} />
  </>
);

export const SuperAdminRoutes = ({ setOpen }) => (
  <>
    <S.Link to={ADMIN.DASHBOARD} onClick={() => handleClick(setOpen)}>
      <S.Head4>My</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        dashboard
      </S.Head4>
    </S.Link>
    <S.Link
      to={SUPER_ADMIN.ALL_ORGANISATIONS}
      onClick={() => handleClick(setOpen)}
    >
      <S.Head4>All</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        accounts
      </S.Head4>
    </S.Link>

    <S.Link to={ADMIN.ALL_FEEDBACK} onClick={() => handleClick(setOpen)}>
      <S.Head4>All</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        feedback
      </S.Head4>
    </S.Link>

    <S.Link to={ADMIN.ALL_CONTENT} onClick={() => handleClick(setOpen)}>
      <S.Head4>All</S.Head4>{' '}
      <S.Head4 weight="bold" color="gray8">
        content
      </S.Head4>
    </S.Link>

    <LogoutRoute setOpen={setOpen} />
  </>
);
